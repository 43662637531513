import React from "react";

import "./Footer.scss";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__heading">Footer</div>
    </div>
  );
}
