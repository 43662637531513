import React from "react";

import IconArrowRight from "@material-ui/icons/ChevronRight";

import "./Teasers.scss";

interface ITeaserProps {
  image: string;
  heading: string;
  text: string;
  link: string;
}

export default function Teaser({ image, heading, text, link }: ITeaserProps) {
  return (
    <div className="teaser">
      <img className="teaser__image" src={image} alt="" />
      <div className="teaser__heading">{heading}</div>
      <div className="teaser__content">
        <div className="teaser__text">{text}</div>
        <a href={link} className="teaser__link">
          <IconArrowRight />
        </a>
      </div>
    </div>
  );
}
