import React from 'react';

import IconSearch from '@material-ui/icons/Search';

import './Search.scss';

export default function Search() {
  return (
    <div className="search" id="search">
      <form>
        <div className="search__heading">
          Suchen Sie etwas Bestimmtes
          <br />
          zu einem Thema?
        </div>
        <div className="search__fieldset">
          <input id="search__input" className="search__input" type="text" />
          <button className="search__btn" type="submit">
            <IconSearch />
          </button>
        </div>
      </form>
    </div>
  );
}
