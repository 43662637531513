import React from 'react';

import './Chat.scss';

const embedUrl =
  process.env.NODE_ENV === 'production' ? '/embed' : '//localhost:3001';

export default function Chat() {
  return (
    <div className="chat">
      <iframe
        title="Avatar Chat"
        src={`${embedUrl}/${window.location.search}`}
        allowFullScreen
      />
    </div>
  );
}
