import React from 'react';

import './App.scss';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Search from './components/Search/Search';
import Chat from './components/Chat/Chat';
import Teasers from './components/Teasers/Teasers';

export default function App() {
  return (
    <div className="container">
      <Header />
      <Chat />
      <Teasers />
      <Search />
      <Footer />
    </div>
  );
}
