import React from "react";

interface IHeaderLinkProps {
  link: string;
  icon: JSX.Element;
  onClick?: (evt: React.MouseEvent<HTMLAnchorElement>) => void;
}

export default function HeaderLink({ link, icon, onClick }: IHeaderLinkProps) {
  return (
    <a href={link} onClick={onClick}>
      {icon}
    </a>
  );
}
