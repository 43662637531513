import React from 'react';

import Teaser from './Teaser';
import ImgTeaser1 from './teaser1.png';
import ImgTeaser2 from './teaser2.png';
import ImgTeaser3 from './teaser3.png';

import IconExpandMore from '@material-ui/icons/ExpandMore';

import './Teasers.scss';

export default function Teasers() {
  return (
    <>
      <div className="teasers">
        <div className="teasers__heading">Themen zu Baufinanzierung</div>
        <div className="teasers__items">
          <Teaser
            image={ImgTeaser1}
            heading="VR-Baufinanzierung"
            text="Unser Konzept für Ihre Wohnträume verständlich erklärt"
            link="//www.etecture.de"
          />
          <Teaser
            image={ImgTeaser2}
            heading="Annuitätendarlehen"
            text="Wichtigste Säule der Baufinanzierung"
            link="//www.etecture.de"
          />
          <Teaser
            image={ImgTeaser3}
            heading="Zinszahlungsdarlehen"
            text="Tilgung bis zum Ende der Laufzeit aussetzen"
            link="//www.etecture.de"
          />
        </div>
      </div>
      <div className="teasers__more">
        <span>Weitere laden</span>
        <IconExpandMore style={{ fontSize: 40 }} />
      </div>
    </>
  );
}
