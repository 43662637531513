import React from "react";

import HeaderLink from "./HeaderLink";
import IconSearch from "@material-ui/icons/Search";
import IconPhone from "@material-ui/icons/Phone";
import IconMenu from "@material-ui/icons/MoreVert";
import ImgLogo from "./logo.svg";

import "./Header.scss";

export default function Header() {
  const scrollSearchIntoView = (evt: React.MouseEvent) => {
    evt.preventDefault();

    document.querySelector("#search")?.scrollIntoView({
      behavior: "smooth"
    });

    window.setTimeout(() => {
      const searchInput: HTMLElement | null = document.querySelector(
        "#search__input"
      );

      searchInput?.focus();
    }, 500);
  };

  return (
    <div className="header">
      <div className="header__inner">
        <div className="header__logo">
          <img src={ImgLogo} alt="" />
        </div>
        <div className="header__tools">
          <HeaderLink
            onClick={scrollSearchIntoView}
            link="#search"
            icon={<IconSearch />}
          />
          <HeaderLink link="#phone" icon={<IconPhone />} />
          <HeaderLink link="#menu" icon={<IconMenu />} />
        </div>
      </div>
    </div>
  );
}
